/*div {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: stretch;*/
/*    justify-content: stretch;*/
/*}*/

.modal-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    height: 100%;
    width: 100%;
}

.box-container {
    display: flex;
    /* flex-direction: column; */
    align-items: stretch;
    justify-content: stretch;
    flex: 1;
    /*border: 2px solid;*/
}

.title-typography {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    /*border: 2px solid*/
}

.left-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 60%;
    /*border: 2px solid;*/
    margin: 30px;
    margin-bottom: 0;
}

.right-box {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: "center";
    /* max-width: 10%; */
    flex: 1;
    /*border: 2px solid;*/
    margin: 10px 0;
}

.chip-success {
    color: greenyellow;
}

.chip-error {
    color: rgba(255, 60, 0, 0.48);
}

.title-cell {
    padding-top: 3rem;
    padding-bottom: 2rem;
}
