.modal-container {
    position: "absolute";
    top: "50%";
    left: "50%";
    transform: "translate(-50%, -50%)";
    width: "80vw";
    height: "80vh";
    background-color: "#1f1f1f";
    border: "2px solid #ccc";
    padding: 4;
}