.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 10px auto 10px auto;
}

.invoice-container {
    margin-bottom: 15em;
}

.title {
    text-align: center;
    padding-bottom: 15px;
}

.date-field {
    margin-left: 0;
}